import { CSSProperties } from 'react'

import { StyleProps as DialogStyles } from 'lib/ui/Dialog/Dialog.types'
import theme, { size } from 'utils/theme'
import colors from 'utils/theme/colors'
import { mediaQuery } from 'utils/theme/mediaQuery'

export const rootStyles: CSSProperties = {
  backgroundColor: colors.surface100,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  padding: '56px 88px',
  [mediaQuery('sm', 'max-width')]: {
    padding: '23.25px 16px'
  }
}
export const layoutWrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  [mediaQuery('sm', 'max-width')]: {
    justifyContent: 'start'
  }
}

export const cardStyles: CSSProperties = {
  backgroundColor: colors.surface50,
  border: `1px solid ${colors.surface200}`,
  boxShadow: '0px 4px 4px -1px #2A2E510A',
  borderRadius: theme.radii.lg
}

export const iconBottom: CSSProperties = {
  display: 'flex',
  width: '100%',
  ['& img' as string]: {
    maxWidth: '140px'
  },
  justifyContent: 'center',
  marginTop: '2rem'
}

export const skeletonAnimation = `
  @keyframes skeleton {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  `
export const dialogStyles: DialogStyles = {
  modal: {
    ...cardStyles,
    borderRadius: theme.radii.xxl
  },
  closeIcon: {
    color: colors.surface500,
    width: size(2.4),
    height: size(2.4)
  }
}
