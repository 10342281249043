import { datadogRum } from '@datadog/browser-rum'
import Cookies from 'js-cookie'
import Button from 'lib/ui/Button/v2'
import Text from 'lib/ui/Text'
import { useRouter } from 'next/router'
import { AppCookie, getCookieExpires } from 'utils/cookies'

import {
  sigInAndPayButton,
  dividerWrapper,
  orText,
  continueAsGuestButton
} from './styles'
import SignInButton from '../SignInButton'
import { useCompanyAndInvoiceContext } from '@/hooks/providers/CompanyAndInvoiceProvider'

const PayableInvoiceLoginButtons = ({
  redirectUrl
}: {
  redirectUrl?: string
}) => {
  const { push } = useRouter()
  const { setPayAsGuest, isCustomInvoice } = useCompanyAndInvoiceContext()

  const handleLoginAsGuest = () => {
    datadogRum.addAction('login as guest', {
      hasTokenCustomer: !!Cookies.get(AppCookie.TokenCustomer),
      hasRefreshCustomer: !!Cookies.get(AppCookie.RefreshCustomer)
    })
    setPayAsGuest(true)
    Cookies.remove(AppCookie.TokenCustomer)
    Cookies.remove(AppCookie.RefreshCustomer)
    Cookies.set(AppCookie.LoggedAsGuest, 'true', {
      expires: getCookieExpires(AppCookie.LoggedAsGuest),
      httpOnly: false
    })

    if (redirectUrl) {
      push(redirectUrl)
    } else {
      push(window.location.pathname)
    }
  }

  return (
    <>
      <SignInButton
        text='Sign in and pay'
        styles={{ ...sigInAndPayButton }}
        redirectUrl={redirectUrl}
      />

      {!isCustomInvoice && (
        <>
          <div
            css={{
              ...dividerWrapper
            }}
          >
            <Text
              tag='span'
              color='surface400'
              fontWeight={400}
              styles={{
                ...orText
              }}
            >
              OR
            </Text>
          </div>

          <Button
            variant='text'
            styles={{
              ...continueAsGuestButton
            }}
            onClick={handleLoginAsGuest}
          >
            Continue as guest
          </Button>
        </>
      )}
    </>
  )
}

export default PayableInvoiceLoginButtons
