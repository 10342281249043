import { CSSProperties } from 'react'

import { useOAuth2UrlLazyQuery } from 'graph/generated/payments/graphql-types'
import { replaceStateParamWithRedirectUrl } from 'utils/ory'

import BaseButton from '@/ui/Button/v2'

const SignInButton = ({
  text,
  styles,
  redirectUrl
}: {
  text?: string
  redirectUrl?: string
  styles?: CSSProperties
}) => {
  const [getOAuth2Url] = useOAuth2UrlLazyQuery()

  const handleSignIn = async () => {
    const url = await getOAuth2Url()
    const redirectTo = redirectUrl ?? window.location.pathname

    window.location.href = redirectTo
      ? replaceStateParamWithRedirectUrl(url?.data?.OAuth2Url, redirectTo)
      : url?.data?.OAuth2Url
  }

  return (
    <BaseButton
      onClick={handleSignIn}
      styles={{
        marginLeft: 'auto',
        width: '104px',
        height: '26px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 700,
        ...styles
      }}
    >
      {text || 'Sign in'}
    </BaseButton>
  )
}

export default SignInButton
