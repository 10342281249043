import { useRouter } from 'next/router'

import { goBackToDashboardButton, sigInAndPayButton } from './styles'
import SignInButton from '../SignInButton'
import useActiveUserInfo from '@/hooks/useActiveUserInfo'
import BaseButton from '@/ui/Button/v2'

const InvalidInvoiceRedirectButtons = () => {
  const { push } = useRouter()
  const { user, loading: loadingUser } = useActiveUserInfo()
  const isLoggedInAsCustomer = user && user.role !== 'guest' && !loadingUser
  const redirectUrl = '/dashboard'

  return (
    <>
      {!isLoggedInAsCustomer && (
        <SignInButton
          text='Sign in to see more'
          styles={{ ...sigInAndPayButton }}
          redirectUrl={redirectUrl}
        />
      )}

      {isLoggedInAsCustomer && (
        <BaseButton
          styles={{
            ...goBackToDashboardButton
          }}
          onClick={() => push(redirectUrl)}
        >
          Go back to dashboard
        </BaseButton>
      )}
    </>
  )
}

export default InvalidInvoiceRedirectButtons
