import Icon from 'lib/ui/Icon'

import { header, logoHeader } from './styles'
import Logo from '@/ui/Logo'

const LoginWithGuestHeader = () => (
  <div css={{ ...header }}>
    <div css={{ ...logoHeader }}>
      <Logo
        context='dashboard'
        defaultAltLogo={
          <Icon
            name='alternativePaymentsLogo'
            width={14}
            height={4}
            viewBox='0 0 170 35'
            color='black'
          />
        }
      />
    </div>
  </div>
)

export default LoginWithGuestHeader
