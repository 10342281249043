import { CSSProperties } from 'react'

import colors from 'utils/theme/colors'
import { mediaQuery } from 'utils/theme/mediaQuery'
import { spacing } from 'utils/theme/spacing'

import { cardStyles } from '../styles'

export const contentWrapper: CSSProperties = {
  ...cardStyles,
  padding: spacing([16, 24, 24], 'px', 1),
  display: 'flex',
  width: '478px',
  height: 'auto',
  alignContent: 'flex-start',
  flexDirection: 'column',
  marginTop: spacing(2),
  [mediaQuery('sm', 'max-width')]: {
    width: '100%',
    padding: spacing(1),
    marginTop: spacing(1)
  }
}

export const textsWrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%'
}

export const invoiceAmountLabel: CSSProperties = {
  fontSize: '14px',
  lineHeight: '21px',
  [mediaQuery('sm', 'max-width')]: {
    fontSize: '12px',
    lineHeight: '18px'
  }
}

export const invoiceAmountText: CSSProperties = {
  fontSize: '24px',
  lineHeight: '36px',
  [mediaQuery('sm', 'max-width')]: {
    fontSize: '20px',
    lineHeight: '30px'
  }
}

export const smallText: CSSProperties = {
  fontSize: '12px',
  lineHeight: '18px',
  [mediaQuery('sm', 'max-width')]: {
    fontSize: '10px',
    lineHeight: '15px'
  }
}

export const payingDateLabel: CSSProperties = {
  ...smallText,
  marginLeft: spacing(1.5),
  [mediaQuery('sm', 'max-width')]: {
    fontSize: '10px',
    lineHeight: '15px',
    marginLeft: spacing(1)
  }
}

export const sigInAndPayButton: CSSProperties = {
  width: '100%',
  height: '43px',
  marginTop: '24px',
  fontSize: '18px',
  lineHeight: '27px'
}

export const dividerWrapper: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '16px',
  color: colors.surface400,
  ['&:before, &:after' as string]: {
    content: '""',
    flex: 1,
    borderBottom: `1px solid ${colors.surface300}`
  }
}

export const orText: CSSProperties = {
  fontSize: '10px',
  lineHeight: '15px',
  margin: '0 16px'
}

export const continueAsGuestButton: CSSProperties = {
  color: colors.surface500,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  marginTop: '8px',
  fontSize: '14px',
  lineHeight: '21px',
  fontWeight: 700
}

export const logoWrapper: CSSProperties = {
  display: 'flex',
  width: '100%',
  ['& img' as string]: {
    maxWidth: '140px'
  },
  justifyContent: 'center',
  marginTop: '32px'
}

export const header: CSSProperties = {
  display: 'flex',
  width: '100%',
  height: '87px',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: '2.5px'
}

export const menuHeader: CSSProperties = {
  display: 'flex'
}

export const logoHeader: CSSProperties = {
  display: 'flex',
  width: '100%',
  [mediaQuery('xs')]: {
    maxWidth: '170px'
  },
  ['& img' as string]: {
    maxWidth: '170px',
    padding: '0px'
  },
  ['& > div' as string]: {
    position: 'relative',
    ['& > img' as string]: {
      padding: 'unset'
    }
  },
  justifyContent: 'center'
}

export const invoiceNumber: CSSProperties = {
  fontSize: '12px',
  lineHeight: '18px',
  marginRight: '8px',
  [mediaQuery('sm', 'max-width')]: {
    fontSize: '10px',
    lineHeight: '15px'
  }
}

export const invoiceNumberAndDownloadWrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  marginLeft: 'auto',
  maxHeight: '28px',
  alignItems: 'center'
}

export const goBackToDashboardButton: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '43px',
  marginTop: '24px',
  fontSize: '18px',
  lineHeight: '27px',
  fontWeight: 700
}

export const invoiceStatusBannerWrapper = (isPaid: boolean): CSSProperties => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '478px',
  height: '80px',
  marginTop: spacing(2),
  borderRadius: '4px',
  padding: spacing(1.5),
  background: isPaid ? colors.success50 : colors.information50,
  border: `0.7px solid ${isPaid ? colors.success200 : colors.information200}`,
  borderLeft: `4px solid ${isPaid ? colors.success200 : colors.information200}`,
  boxShadow: '0px 8px 12px 0px #2A2E510A',
  [mediaQuery('sm', 'max-width')]: {
    width: '100%',
    padding: spacing([0.5, 1])
  }
})

export const invoiceStatusBannerIconWrapper = (
  isPaid: boolean
): CSSProperties => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  minWidth: '32px',
  height: '32px',
  minHeight: '32px',
  borderRadius: '50%',
  backgroundColor: isPaid ? colors.success100 : colors.information200
})
