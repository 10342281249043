import { InvoiceStatus } from 'graph/generated/payments/graphql-types'
import Text from 'lib/ui/Text'
import colors from 'utils/theme/colors'
import { spacing } from 'utils/theme/spacing'

const InvoiceStatusBadge = ({
  invoiceStatus
}: {
  invoiceStatus: InvoiceStatus
}) => {
  const isPaid =
    invoiceStatus === InvoiceStatus.Paid ||
    invoiceStatus === InvoiceStatus.Received

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80px',
        height: '24px',
        backgroundColor: isPaid ? colors.success50 : colors.surface50,
        marginLeft: spacing(0.5),
        padding: '4px 8px',
        border: `0.7px solid ${isPaid ? colors.success100 : colors.surface300}`,
        borderRadius: '8px'
      }}
    >
      <Text
        tag='span'
        color={isPaid ? 'success200' : 'surface500'}
        fontWeight={700}
        styles={{
          fontSize: '12px',
          lineHeight: '18px'
        }}
      >
        {isPaid ? 'Paid' : 'Archived'}
      </Text>
    </div>
  )
}

export default InvoiceStatusBadge
