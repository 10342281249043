import { Archive, Checkmark } from '@carbon/icons-react'
import { InvoiceStatus } from 'graph/generated/payments/graphql-types'
import Text from 'lib/ui/Text'
import colors from 'utils/theme/colors'
import { spacing } from 'utils/theme/spacing'

import {
  invoiceStatusBannerIconWrapper,
  invoiceStatusBannerWrapper
} from './styles'

const InvoiceStatusBanner = ({
  invoiceStatus,
  invoiceNumber
}: {
  invoiceStatus: InvoiceStatus
  invoiceNumber: string
}) => {
  const isPaid =
    invoiceStatus === InvoiceStatus.Paid ||
    invoiceStatus === InvoiceStatus.Received

  const description = isPaid
    ? `The invoice ${invoiceNumber} has already been marked as paid.`
    : `The invoice ${invoiceNumber} has been archived.`

  return (
    <div
      css={{
        ...invoiceStatusBannerWrapper(isPaid)
      }}
    >
      <div
        css={{
          ...invoiceStatusBannerIconWrapper(isPaid)
        }}
      >
        {isPaid ? (
          <Checkmark size={16} css={{ fill: colors.surface50 }} />
        ) : (
          <Archive size={16} css={{ fill: colors.surface50 }} />
        )}
      </div>
      <Text
        color={isPaid ? 'surface400' : 'surface500'}
        fontWeight={400}
        styles={{
          fontSize: '12px',
          lineHeight: '18px',
          marginLeft: spacing(1)
        }}
      >
        {description}
      </Text>
    </div>
  )
}

export default InvoiceStatusBanner
