import { CSSProperties } from 'react'

import colors from 'utils/theme/colors'
import { mediaQuery } from 'utils/theme/mediaQuery'
import { spacing } from 'utils/theme/spacing'

import { layoutWrapper, rootStyles, skeletonAnimation } from '../styles'

export const LoginWithGuestSkeletonLoading = () => {
  const loadingStyles: CSSProperties = {
    animation: `${skeletonAnimation} 1.25s ease-in-out infinite`,
    backgroundColor: colors.surface300
  }

  return (
    <div
      css={{
        ...rootStyles
      }}
    >
      <div css={{ ...layoutWrapper }}>
        <div
          css={{
            ...loadingStyles,
            width: '94px',
            height: '40px',
            borderRadius: '36px'
          }}
        />
        <div
          css={{
            ...loadingStyles,
            width: '478px',
            height: '176px',
            borderRadius: '8px',
            marginTop: spacing(3),
            [mediaQuery('sm', 'max-width')]: {
              width: '343px',
              height: '245px'
            }
          }}
        />
      </div>
    </div>
  )
}

export default LoginWithGuestSkeletonLoading
