import { InvoiceStatus } from 'graph/generated/payments/graphql-types'
import Icon from 'lib/ui/Icon'
import Text from 'lib/ui/Text'

import InvalidInvoiceRedirectButtons from './InvalidInvoiceRedirectButtons'
import InvoiceDataInfo from './InvoiceDataInfo'
import InvoiceStatusBanner from './InvoiceStatusBanner'
import LoginWithGuestHeader from './LoginWithGuestHeader'
import LoginWithGuestSkeletonLoading from './LoginWithGuestSkeletonLoading'
import PayableInvoiceLoginButtons from './PayableInvoiceLoginButtons'
import {
  contentWrapper,
  invoiceNumber,
  invoiceNumberAndDownloadWrapper,
  logoWrapper,
  textsWrapper
} from './styles'
import { layoutWrapper, rootStyles } from '../styles'
import { useCompanyAndInvoiceContext } from '@/hooks/providers/CompanyAndInvoiceProvider'
import InvoiceDownload from '@/ui/Pdfs/InvoiceDownload'

const LoginWithGuest = ({
  redirectUrl,
  showSkeletonLoading
}: {
  redirectUrl?: string
  showSkeletonLoading?: boolean
}) => {
  const { loading, invoice, company, isCustomInvoice } =
    useCompanyAndInvoiceContext()
  const companyLogo = company?.paymentsPortalCustomization?.logoUrl
  const invoiceStatus = invoice?.status || InvoiceStatus.Upcoming

  const isInvoicePayable =
    invoiceStatus === InvoiceStatus.Overdue ||
    invoiceStatus === InvoiceStatus.Upcoming

  if (loading || !invoice || showSkeletonLoading) {
    return <LoginWithGuestSkeletonLoading />
  }

  return (
    <div
      css={{
        ...rootStyles
      }}
    >
      <div css={{ ...layoutWrapper }}>
        <LoginWithGuestHeader />

        {!isInvoicePayable && (
          <InvoiceStatusBanner
            invoiceStatus={invoiceStatus}
            invoiceNumber={invoice?.invoiceNumber}
          />
        )}

        <div
          css={{
            ...contentWrapper
          }}
        >
          <div
            css={{
              ...textsWrapper
            }}
          >
            <InvoiceDataInfo />

            {!isCustomInvoice && (
              <div style={{ ...invoiceNumberAndDownloadWrapper }}>
                <Text
                  color='surface500'
                  styles={{
                    ...invoiceNumber
                  }}
                  fontWeight={700}
                >
                  {`Invoice ${invoice?.invoiceNumber}`}
                </Text>

                <InvoiceDownload
                  invoice={invoice}
                  buttonSize='sm'
                  ignoreMargin
                  btnProps={{
                    styles: {
                      padding: '5px',
                      ['& svg' as string]: { margin: 'unset' }
                    }
                  }}
                />
              </div>
            )}
          </div>

          {isInvoicePayable && (
            <PayableInvoiceLoginButtons redirectUrl={redirectUrl} />
          )}

          {!isInvoicePayable && <InvalidInvoiceRedirectButtons />}
        </div>

        {companyLogo && (
          <div
            css={{
              ...logoWrapper
            }}
          >
            <Icon
              name='poweredByV2'
              width={14}
              height={4}
              viewBox='0 0 93 36'
              color='black'
            />
          </div>
        )}
      </div>
    </div>
  )
}
export default LoginWithGuest
