import { InvoiceStatus } from 'graph/generated/payments/graphql-types'
import Text from 'lib/ui/Text'
import { formatCurrency } from 'utils/numbers'

import InvoiceStatusBadge from './InvoiceStatusBadge'
import {
  invoiceAmountLabel,
  invoiceAmountText,
  payingDateLabel,
  smallText
} from './styles'
import { useCompanyAndInvoiceContext } from '@/hooks/providers/CompanyAndInvoiceProvider'
import { formatDate } from '@/utils/dates/format'

const InvoiceDataInfo = () => {
  const { invoice } = useCompanyAndInvoiceContext()
  const invoiceStatus = invoice?.status || InvoiceStatus.Upcoming
  const isInvoicePayable =
    invoiceStatus === InvoiceStatus.Overdue ||
    invoiceStatus === InvoiceStatus.Upcoming

  return (
    <div>
      <Text
        color='surface400'
        fontWeight={600}
        styles={{
          ...invoiceAmountLabel
        }}
      >
        Invoice amount due
      </Text>

      <div css={{ display: 'flex', alignItems: 'center' }}>
        <Text
          color='surface500'
          fontWeight={700}
          styles={{
            ...invoiceAmountText
          }}
        >
          {formatCurrency({ amount: invoice?.outstandingAmount })}
        </Text>

        {!isInvoicePayable && (
          <InvoiceStatusBadge invoiceStatus={invoice?.status} />
        )}
      </div>

      <Text
        fontWeight={600}
        color='surface400'
        tag='span'
        styles={{
          ...smallText
        }}
      >
        Due
      </Text>
      <Text
        fontWeight={600}
        color='surface500'
        tag='span'
        margin={[0, 0, 0, 0.2]}
        styles={{
          ...smallText
        }}
      >
        {formatDate(invoice?.dueDate, 'MMM DD, YYYY')}
      </Text>

      {isInvoicePayable && (
        <>
          <Text
            fontWeight={600}
            color='surface400'
            tag='span'
            styles={{
              ...payingDateLabel
            }}
          >
            Paying
          </Text>
          <Text
            fontWeight='bold'
            color='surface500'
            tag='span'
            margin={[0, 0, 0, 0.2]}
            styles={{
              ...smallText
            }}
          >
            today
          </Text>
        </>
      )}
    </div>
  )
}
export default InvoiceDataInfo
